@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl !important;
    @apply font-bold !important;
  }
  h2 {
    @apply text-3xl !important;
    @apply font-bold !important;
  }
  h3 {
    @apply text-2xl !important;
    @apply font-bold !important;
  }
  h4 {
    @apply text-xl !important;
    @apply font-semibold !important;
  }
  h5 {
    @apply text-lg !important;
    @apply font-semibold !important;
  }
  h6 {
    @apply text-sm !important;
    @apply font-semibold !important;
  }
}
body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* custom fonts */
@font-face {
  font-family: "pecita";
  src: url("./assets/fonts/pecita.book.otf") format("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap");

/* hide scroll bar */
/* ::-webkit-scrollbar {
  display: none;
} */

@media (max-width: 600px) {
  .mobileView {
    display: none;
  }
}

.edits:hover {
  color: red;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.dashboard .apexcharts-legend {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
}
.dashboard .apexcharts8h5f4mefj {
  width: 60% !important;
  height: 100% !important;
  margin: auto !important;
}
@media screen and (max-width: 550px) {
  .MuiBox-root {
    width: 90% !important;
  }
}

.editorClassName {
  max-height: 200px !important;
  height: 100% !important;
  width: 800px !important;
  overflow: auto !important;
}

::-webkit-scrollbar {
  width: 5px !important;
  margin-left: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: none !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.rdw-image-modal-upload-option-label {
  font-size: 10px !important;
}

@media screen and (max-width: 950px) {
  .css-14m2c3k {
    width: 90% !important;
  }
}
